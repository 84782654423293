.products-product {
  padding-top: 10vh;
  padding-bottom: 10vh;

  .row {
    align-items: center;
  }

  &:nth-child(2n) {
    background-color: $gray-lightest;

    .row {
      flex-direction: row-reverse;
    }
  }
}

.products-product-normal-image {
  line-height: 150px;

  img {
    vertical-align: middle;
  }
}
