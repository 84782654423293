$enable-flex: true;
$enable-rounded: false;

$brand-primary: #ec352f;

$font-weight: 300;
$font-family-sans-serif: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif;

$strong-font-weight: 700;

$headings-font-weight: 400;
$headings-font-family: 'Roboto Condensed', $font-family-sans-serif;

$blockquote-font-size: 1rem;

$spacer: 1rem;
$spacer-x: $spacer;
$spacer-y: $spacer;
$spacers: (
  0: (
    x: 0,
    y: 0
  ),
  1: (
    x: $spacer-x,
    y: $spacer-y
  ),
  2: (
    x: ($spacer-x * 2),
    y: ($spacer-y * 2)
  ),
  3: (
    x: ($spacer-x * 3),
    y: ($spacer-y * 3)
  ),
  4: (
    x: ($spacer-x * 4),
    y: ($spacer-y * 4)
  ),
  5: (
    x: 5vw,
    y: 5vh
  ),
  10: (
    x: 10vw,
    y: 10vh
  ),
  15: (
    x: 15vw,
    y: 15vh
  ),
  20: (
    x: 20vw,
    y: 20vh
  )
);

$container-max-widths: (
  lg: 960px
);
