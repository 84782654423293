.process-step {
  align-items: center;
  margin-top: $spacer * 6;
  margin-bottom: $spacer * 6;

  text-align: center;

  &:nth-child(2n + 1) {
    flex-direction: row-reverse;
  }
}

.process-step-number {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: $spacer;

  font-size: 6rem;

  border-bottom: 2px solid $gray-light;
}

.process-step-title {
  margin-bottom: $spacer;

  font-size: 3rem;
  text-transform: uppercase;
}

.process-substeps {
  margin-left: -($grid-gutter-width / 2);
  margin-right: -($grid-gutter-width / 2);
  margin-bottom: $spacer;
  padding: 0;

  list-style: none;

  li {
    display: inline-block;
    margin-left: $grid-gutter-width / 2;
    margin-right: $grid-gutter-width / 2;
    margin-bottom: $spacer;

    text-transform: uppercase;

    span {
      display: inline-block;
      line-height: 1.2;

      vertical-align: middle;
    }
  }
}

.process-substeps-circles {
  $size: 120px;

  li {
    width: $size;
    height: $size;

    color: $gray-lightest;
    background-color: $gray-dark;

    line-height: $size;

    border-radius: $size / 2;
  }
}
