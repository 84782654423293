.project {
  @extend .container;
  @extend .text-xs-center;

  .lead {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .section-subheader {
    margin-top: 4rem;
    margin-bottom: 2rem;
  }

  p.lead + .section-subheader {
    margin-top: 3rem;
  }
}

//

.video-gallery {
  @extend .m-b-1;
}
.video-gallery-player {
  @extend .embed-responsive;
  @extend .embed-responsive-16by9;

  iframe {
    @extend .embed-responsive-item;
  }
}
.video-gallery-thumbnails {
  @extend .row;
}
.video-gallery-item {
  @extend .col-xs-6;
  @extend .col-sm-3;

  margin-top: $grid-gutter-width;

  &::after {
    content: '';

    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;

    display: block;
    width: 0;
    height: 0;

    border: 40px solid transparent;
    border-top-width: 20px;
    border-bottom-width: 20px;
    border-left-color: $brand-primary;

    opacity: 0;
    transform: translate(-15px, -10px);
    transition: opacity 0.3s, transform 0.3s;

    pointer-events: none;
  }

  &:hover::after {
    opacity: 1;
    transform: translate(-15px, -20px);
  }

  img {
    @extend .img-fluid;
    @extend .w-100;
  }
}

//

.photo-gallery {
  @extend .row;
}
.photo-gallery-item {
  @extend .col-sm-4;
  @extend .p-x-0;

  img {
    @extend .img-fluid;
    @extend .w-100;
  }
}

//

.project-item {
  align-items: center;
}
.project-item-alternate {
  flex-direction: row-reverse;
}
