.header {
  background-color: transparentize($gray-lightest, 0.25);
}

.jumbotron {
  text-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);

  h1, h2, strong {
    color: white;
  }
}

.home-featured {
  background-color: $gray-lighter;
}

.home-clients {
  img {
    width: auto;
    height: 55px;
    margin-bottom: $spacer;
  }
}

.home-location {
  background: url('../images/city.jpg') no-repeat center bottom;
  background-size: cover;

  .col-md-6 {
    color: $gray-lightest;
    background-color: transparentize($brand-primary, 0.4);
  }

  hr {
    border-top-color: $gray-lightest;
  }

  a {
    color: $gray-lightest;
    text-decoration: none;
  }
}

.home-natural-form {
  font-size: 1.5rem;

  color: $gray;
}

.natural-form-control {
  border: 0 none;
  outline: 0 none;
  border-bottom: 4px solid $brand-primary;

  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;

  border-radius: 0;
  color: $body-color;
  background-color: transparent;
}
