.team-member-photo {
  max-width: 200px;
}

.team-member-name {
  font-family: inherit;
}

.organization-logo {
  max-width: 300px;
  max-height: 80px;
}
