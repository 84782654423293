.filters .btn-link {
  color: $body-color;
}

@include media-breakpoint-up (lg) {
  .filters {
    padding-left: 0;
    padding-right: 0;
  }
}
