& {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  background-color: transparentize($gray-lightest, 0.75);
}

.collapse {
  @include media-breakpoint-down (sm) {
    clear: both;
    text-align: center;
  }
}

.navbar-toggler {
  margin-top: 0.3rem;
  margin-bottom: 0;

  border-color: inherit;

  border-radius: $border-radius;
}

.nav-link {
  padding-top: 0.75rem;
  padding-bottom: 0rem;

  color: $body-color;

  @include hover-focus {
    color: $brand-primary;
  }
}

@include media-breakpoint-down (sm) {
  & {
    background-color: transparentize($gray-lightest, 0.25);
  }

  .nav-link.active,
  .nav-item.active .nav-link {
    color: $brand-primary;
  }
}

@include media-breakpoint-up (md) {
  .nav-item + .nav-item {
    margin-left: 2rem;
  }

  .nav-link.active,
  .nav-item.active .nav-link {
    border-bottom: 3px solid $brand-primary;
  }
}
